import React from 'react';
export const PriceOnDemand = ({
  onDemandTitle,
  onDemandText,
}: {
  onDemandTitle?: string;
  onDemandText?: string;
}) => (
  <div className='mb-8'>
    {onDemandTitle && (
      <div className="text-24 text-neutral-101 font-serif mb-16">{onDemandTitle}</div>
    )}
    {onDemandText && (
      <div className="text-12 text-neutral-101 mb-16">{onDemandText}</div>
    )}
  </div>
);
