import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { List, Node } from 'datocms-structured-text-utils';
import {
  textTitle2,
  textTitle3,
} from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { fontBodyMini } from 'libs/growth-platform-brand-system-v2/src/styles/typographyNew';

export const getParagraphStyle = (ancestors: Node[]) => {
  if (ancestors?.[0]?.type === 'listItem') return classNames('py-0');

  return classNames(fontBodyMini, 'py-8');
};

export const getListStyle = (node: List) => {
  if (node.style === 'bulleted') return 'list-disc';

  return 'list-decimal';
};

export const getHeadingStyle = (level: number) => {
  if (level === 2) return classNames(textTitle2, 'lg:text-32');
  if (level === 3) return classNames(textTitle3, 'text-24 lg:text-28');
  if (level === 4 || level === 5 || level === 6)
    return classNames(textTitle3, 'text-20 md:text-24'); // quick & dirty fix -> remove this after text-title-3 update
};
