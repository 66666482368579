import {
  StructuredText as IStructuredText,
  Mark,
} from 'datocms-structured-text-utils';

export const getMarksStyle = (marks: Mark[] | undefined) => {
  if (!marks) return '';

  return marks.reduce((markStyles: string, prevMark: Mark) => {
    switch (prevMark) {
      case 'strong':
        return `${markStyles} font-semibold`;
      case 'emphasis':
        return `${markStyles} italic`;
      case 'strikethrough':
        return `${markStyles} line-through`;
      case 'underline':
        return `${markStyles} underline`;
      default:
        return markStyles;
    }
  }, '');
};

export const getMarksTag = (
  marks: Mark[] | undefined,
) => {
  return marks?.includes('strong') ? 'strong' : 'span';
};

/**
 * Converts a plain string to a simple StructuredText (only one paragraph)
 * @param text
 * @returns StructuredText object that contains one paragraph with param text
 */
export const plainTextToStructuredText = (text: string): IStructuredText => {
  return {
    value: {
      schema: 'dast',
      document: {
        type: 'root',
        children: [
          {
            type: 'paragraph',
            children: [
              {
                type: 'span',
                value: text,
              },
            ],
          },
        ],
      },
    },
  };
};

export const getLinkAttributesFromStructuredText = (meta: any[]) => {
  const attrs = {
    target: undefined,
  };
  const target = meta?.find((att) => att.id === 'target');
  if (target) {
    attrs.target = target?.value;
  }

  return attrs;
};
