import { render } from 'datocms-structured-text-to-plain-text';
import {
  BlockNode,
  Heading,
  StructuredText as IDatoStructuredText,
  Link,
  List,
  ListItem,
  Node,
  Paragraph,
  Span,
  isHeading,
  isLink,
  isList,
  isListItem,
  isParagraph,
  isSpan,
} from 'datocms-structured-text-utils';
import React, { FC, Fragment } from 'react';
import {
  StructuredText as DatoStructuredText,
  renderRule,
} from 'react-datocms/structured-text';

import {
  getLinkAttributesFromStructuredText,
  getMarksStyle,
  getMarksTag,
} from './structuredTextUtils';

import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { findStringInReactNode } from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { getHeadingStyle, getListStyle, getParagraphStyle } from './logic';

export interface RenderContext<N extends Node> {
  node: N;
  children:
    | Array<
        | string
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      >
    | undefined;
  key: string;
  ancestors: Node[];
}
export type renderFunction<N extends Node> = (
  ctx: RenderContext<N>,
) => JSX.Element;

export interface StructuredTextProps {
  text: IDatoStructuredText;
  className?: string;
  renderHeading?: renderFunction<Heading>;
  renderSpan?: renderFunction<Span>;
  renderParagraph?: renderFunction<Paragraph>;
  renderLink?: renderFunction<Link>;
  renderList?: renderFunction<List>;
  renderListItem?: renderFunction<ListItem>;
}

export const StructuredText: FC<StructuredTextProps> = ({
  text,
  className,
  renderHeading,
  renderSpan,
  renderParagraph,
  renderLink,
  renderList,
  renderListItem,
}) => {
  return (
    <DatoStructuredText
      data={text}
      customNodeRules={[
        renderRule(isHeading, ({ node, children, key, ancestors }) => {
          if (renderHeading)
            return renderHeading({ node, children, key, ancestors });
          const HeadingElement: keyof JSX.IntrinsicElements = `h${node.level}`;
          const textStyle = getHeadingStyle(node.level);
          const id =
            render(node as BlockNode)?.replace(/\W+/g, '-') ?? undefined;

          return (
            <HeadingElement
              id={id}
              key={key}
              className={classNames(
                textStyle,
                'py-8',
                'whitespace-pre-line',
                className,
              )}
            >
              {children}
            </HeadingElement>
          );
        }),
        renderRule(isSpan, ({ node, children, key, ancestors }) => {
          if (renderSpan) return renderSpan({ node, children, key, ancestors });
          const textStyle = getMarksStyle(node.marks);
          const Tag = getMarksTag(node.marks);

          if (textStyle === '')
            return <Fragment key={key}>{node.value}</Fragment>; // don't return span if there is no need to do inline style

          return (
            <Tag
              key={key}
              className={classNames(
                textStyle,
                className,
                'whitespace-pre-line',
              )}
            >
              {node.value}
            </Tag>
          );
        }),
        renderRule(isParagraph, ({ node, children, key, ancestors }) => {
          if (renderParagraph)
            return renderParagraph({ node, children, key, ancestors });

          return (
            <p
              key={key}
              className={`${getParagraphStyle(ancestors)} ${className ?? ''}`}
            >
              {children}
            </p>
          );
        }),
        renderRule(isLink, ({ node, children, key, ancestors }: any) => {
          if (renderLink) return renderLink({ node, children, key, ancestors });
          const attrs = getLinkAttributesFromStructuredText(node.meta);

          return (
            <ButtonTracking
              labelTracking={findStringInReactNode(children)}
              component={'a'}
              section="StructuredText"
              index={key}
              href={node.url}
              className={classNames(
                `text-blue-101 no-underline py-8`,
                className,
              )}
              key={key}
              {...attrs}
            >
              {children}
            </ButtonTracking>
          );
        }),
        renderRule(isList, ({ node, children, key, ancestors }) => {
          if (renderList) return renderList({ node, children, key, ancestors });
          const ListElement: keyof JSX.IntrinsicElements =
            node.style === 'bulleted' ? 'ul' : 'ol';

          return (
            <ListElement
              key={key}
              className={classNames(
                `ml-1em py-8`,
                'whitespace-pre-line',
                getListStyle(node),
                className,
              )}
            >
              {children}
            </ListElement>
          );
        }),
        renderRule(isListItem, ({ node, children, key, ancestors }) => {
          if (renderListItem)
            return renderListItem({ node, children, key, ancestors });

          return (
            <li
              key={key}
              className={classNames(
                `ml-8 pl-8`,
                'whitespace-pre-line',
                className,
              )}
            >
              {children}
            </li>
          );
        }),
      ]}
    />
  );
};
