import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { HeroProps } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import {
  getGoogleAnalyticsId,
  track,
} from 'libs/growth-platform-brand-system-v2/src/utils/tracking';
import { createSchema, morphism } from 'morphism';
import React from 'react';
import { StructuredText } from '../../components/StructuredText';
import { TrackingVideo } from '../../components/TrackingVideo';
import { schemaImage } from '../components/schemaImage';
import { schemaImageDisabledGatsby } from '../components/schemaImageDisabledGatsby';
import {
  filterIsSelfServeButMobile,
  schemaDatoButton,
} from '../schemaDatoButton';
import { ImageProps } from 'libs/growth-platform-brand-system-v2/src/components/Image/types';

export const schemaHero = createSchema<HeroProps, any>({
  backgroundColor: 'backgroundColor',
  button: {
    path: [
      'buttonLink',
      'buttonTitle',
      'buttonInternalLink',
      'onClickButton',
      'onClick',
      'locale',
    ],
    fn: (propertyValue: any) => {
      const {
        buttonLink,
        buttonTitle,
        buttonInternalLink,
        onClickButton,
        onClick,
        locale,
      } = propertyValue;
      if (
        buttonTitle &&
        (buttonLink || buttonInternalLink || onClickButton || onClick)
      ) {
        const btn = morphism(schemaDatoButton, {
          title: buttonTitle,
          variant: 'primary',
          className: 'w-full md:w-auto',
          url: buttonLink,
          locale,
          internalUrl: buttonInternalLink,
          onClick: onClickButton || onClick,
        });

        return (
          <ButtonTracking
            component={ButtonV2}
            {...btn}
            section="hero"
            labelTracking={btn.title}
          />
        );
      }
      return;
    },
  },
  additionalButton: {
    path: ['additionalButton', 'locale'],
    fn: ({ additionalButton, locale }: any) => {
      if (additionalButton) {
        const btns = filterIsSelfServeButMobile(additionalButton);
        const _additionalButton = btns?.map((btn) =>
          morphism(schemaDatoButton, {
            ...btn,
            locale,
          }),
        );
        return _additionalButton?.map((btn, key) => (
          <ButtonTracking
            component={ButtonV2}
            {...btn}
            key={key}
            className="w-full md:w-auto"
            labelTracking={btn.title}
          />
        ));
      }
      return;
    },
  },
  image: {
    path: 'image',
    fn: (propertyValue: any) => {
      if (propertyValue?.url) {
        return {
          ...morphism(schemaImage, {
            ...propertyValue,
          }),
          sizes: [
            '(max-width: 350px) 317px',
            '(max-width: 480px) 444px',
            '(max-width: 1080px) 720px',
            '(max-width: 1240px) 825px',
            '1202px',
          ],
        } as ImageProps;
      }
      return undefined;
    },
  },
  title: 'title',
  subtitle: 'subtitle',
  isClientHero: 'isClientHero',
  heroClientProps: {
    path: 'heroClientProps',
    fn: (propertieValue, source) => {
      if (source?.isClientHero) {
        return {
          VideoPlayer: source?.videoLink ? (
            <TrackingVideo url={source?.videoLink} />
          ) : undefined,
          logo: {
            ...morphism(schemaImageDisabledGatsby, source?.logo),
            sizes: ['120px'],
          } as ImageProps,
          classnameThumbnail: source?.classnameThumbnail,
          classnameBoxThumbnail: source?.classnameBoxThumbnail,
          collectiveAgreement: source?.collectiveAgreement,
          collectiveAgreementLabel: source?.collectiveAgreementLabel,
          clientSize: source?.clientSizeLink?.clientSize,
          clientSizeLabel: source?.clientSizeLabel,
          contentTitle: source?.contentTitle,
          onClickVideoThumbnail: () =>
            track({
              gtm: {
                event: 'Toggle Interaction',
                properties: {
                  componentName: 'HeroClient',
                  toggleLabel: 'NA/button open video modal',
                  gaClientID: getGoogleAnalyticsId(),
                },
              },
            }),
          children: source?.contentParagraph ? (
            <StructuredText text={source?.contentParagraph} />
          ) : null,
          sectorLabel: source?.sectorLabel,
          sector: source?.sector?.clientSector,
          location: source?.location,
          locationLabel: source?.locationLabel,
        };
      }
      return;
    },
  },
  trustpilotWidget: 'trustpilotWidget',
});
