import { graphql } from 'gatsby';
import { Hero } from 'libs/growth-platform-brand-system-v2/src/templates/Hero';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { morphism } from 'morphism';
import React, { useState } from 'react';
import { DatoCmsPricingPageV3, Maybe } from '../../../types/dato';
import { TemplateBlocksZone } from '../../components/TemplateBlocksZone';
import Layout from '../../layout';
import { HeadWrapper } from '../../layout/HeadWrapper';
import { schemaBaseTemplate } from '../../morphismSchema/pages/schemaBaseTemplate';
import { schemaHero } from '../../morphismSchema/templates/schemaHero';
import { TablePrice } from './TablePrice';

export const Head = ({ data, pageContext, location }: any) => (
  <HeadWrapper
    data={data}
    pageContext={pageContext}
    location={location}
    templateName="PricingPageV3"
    dataName={'datoCmsPricingPageV3'}
    largestContentfullPaintImg={null}
  />
);

export const query = graphql`
  query DatoPricingPageV3($locale: String, $id: String) {
    datoCmsPricingPageV3(locale: $locale, id: { eq: $id }) {
      ...PricingPageV3
    }
  }
`;

const PricingPageV3 = ({ data, pageContext }: any) => {
  const [openTablePrice, setOpenTablePrice] = useState(false);
  const doc: Maybe<DatoCmsPricingPageV3> | undefined =
    data.datoCmsPricingPageV3;
  const _data = morphism(schemaBaseTemplate, {
    ...doc,
    pageContext,
  });

  const locale = pageContext?.locale;
  const pricing = doc?.pricing;
  const className2Inputs =
    doc?.numberEmployeesInput &&
    doc?.numberEmployeesInput?.length > 0 &&
    doc?.firstPayroll &&
    doc?.firstPayroll?.length > 0
      ? 'hiddenHeroSubtitle'
      : undefined;
  return (
    <Layout
      pageContext={pageContext}
      data={doc}
      tracking={{ pageType: 'pricing' }}
      templateName="PricingPageV3"
      className={classNames(className2Inputs, 'layout')}
    >
      {_data?.hero && <Hero {...morphism(schemaHero, _data?.hero)} />}
      {pricing && (
        <TablePrice
          doc={doc}
          locale={locale}
          setOpenTablePrice={setOpenTablePrice}
        />
      )}
      {_data?.blocks.length > 0 && (
        <TemplateBlocksZone
          {..._data}
          doc={doc}
          options={{ openTablePrice, setOpenTablePrice }}
        />
      )}
    </Layout>
  );
};
export default PricingPageV3;
