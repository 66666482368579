import { ImageProps } from '@payfit/growth-platform-brand-system-v2/components/Image/types';
import { createSchema } from 'morphism';

export const schemaImageDisabledGatsby = createSchema<
  Omit<ImageProps, 'sizes'>,
  any
>({
  alt: {
    path: 'alt',
    fn: (propertyValue: string, source: any) => {
      return propertyValue || source?.altDefault || '';
    },
  },
  src: {
    path: 'url',
    fn: (url: any) => {
      return url;
    },
  },
  loading: {
    path: '',
    fn: () => {
      return 'lazy';
    },
  },
  width: 'width',
  height: 'height',
});
